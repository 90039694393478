<template>
    <div class="app">
        <router-view/>
    </div>
</template>

<style>
    *,
    *::before,
    *::after{
        box-sizing: border-box;
    }
    *{
        margin: 0;
        padding: 0;
    }
    .app{
        font-family: "Roboto Slab", serif;
    }
    button{
        border: none;
        cursor: pointer;
    }
    a{
        color: #000;
    }
    .container{
        max-width: 1270px;
        padding: 0 15px;
        margin: 0 auto;
    }
    .input{
        border-radius: 7px;
        border: 1px solid #d8d8d8;
        padding: 5px 10px;
        outline: none;
        background: transparent;
    }
    .btn{
        background: transparent;
        border: 1px solid #d8d8d8;
        border-radius: 7px;
        transition: all linear .2s;
        padding: 10px 25px;
        font-size: 16px;
        cursor: pointer;
    }
    .btn:hover{
        background-color: #d8d8d8;
    }
    .selected{
        background-color: #d8d8d8;
    }
</style>
